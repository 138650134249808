"use client";

import { useWatch } from "react-hook-form";
import classNames from "classnames";
import { Label } from "./Label";
import { PropsWithClassNameAndChildren } from "../../types";
import { positionStyles, textStyles } from "../../styles";

export interface Props extends PropsWithClassNameAndChildren {
  name: string;
  maxCharacterCount: number;
}
export function LabelWithCount({ name, children, className, maxCharacterCount }: Props) {
  const value = useWatch({ name });
  const characterCount = (value || "").replace(/\*\*/g, "").replace(/_/g, "").length;
  const countOver = characterCount > maxCharacterCount;

  return (
    <Label
      className={className}
      name={name}
      extra={
        <div
          className={classNames(
            positionStyles.absolute,
            positionStyles.top0,
            positionStyles.right0,
            textStyles.neutral400,
            {
              [textStyles.errorMedium]: countOver,
            },
          )}
        >
          {characterCount}/{maxCharacterCount}
        </div>
      }
    >
      {children}
    </Label>
  );
}
