import { BusinessPayload, BusinessSchema } from "schemas";
import {
  FileLanding,
  FormProvider,
  FormTag,
  ImageInput,
  InlineTextInput,
  borderStyles,
  flexStyles,
  marginStyles,
} from "ui";
import classNames from "classnames";
import { Menu } from "./Menu";
import { updateBusiness, uploadImage, useCurrentBusiness } from "../../../store";
import { EditWrapper } from "../../blocks";
import { useNavigate, useLocation } from "react-router";

export function EditBusinessLogo() {
  const business = useCurrentBusiness();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onSubmit = async (payload: BusinessPayload) => {
    await updateBusiness(payload);
    navigate(".");
  };

  const open = pathname.endsWith("blocks/logo");

  const onClick = () => {
    const to = open ? "." : `blocks/logo`;
    navigate(to, { relative: "path", preventScrollReset: true });
  };

  const editor = open && (
    <FormProvider<BusinessPayload> schema={BusinessSchema} defaultValues={business}>
      <FormTag<BusinessPayload> onSubmit={onSubmit}>
        <ImageInput
          className={flexStyles.alignSelfCenter}
          uploadImage={uploadImage}
          urlValue
          cropAspect="Round"
          name="logo"
        />
        <InlineTextInput<BusinessPayload>
          label="Logo URL"
          size="Text"
          name="externalUrl"
          placeholder="https://..."
          onSubmit={onSubmit}
        />
        <Menu<BusinessPayload> onSubmit={onSubmit} onCancel={() => navigate(".")} />
      </FormTag>
    </FormProvider>
  );
  return (
    <EditWrapper open={open} onClick={onClick} editElement={editor}>
      {business.logo ? (
        <img
          src={`${business.logo}/logo`}
          width="160"
          height="160"
          className={classNames(borderStyles.radiusRound, marginStyles.centered)}
          alt={`${business.name} logo`}
        />
      ) : (
        <FileLanding prompt="Add your logo" round />
      )}
    </EditWrapper>
  );
}
