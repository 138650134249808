import { Asserts, number, object, string } from "yup";
import { LinkValidation } from "./Link";
import { BusinessPlan } from "../enums";
import { BookingIncrementValues, SoonestBookingHoursValues } from "../consts";

export const BusinessNameString = string().required("Business name is required");

export const BusinessSchema = object({
  name: BusinessNameString,
  link: LinkValidation,
  externalUrl: string().url().nullable(),
  description: string().nullable().max(300, "Description can not be longer than 300 characters"),
  logo: string().url().nullable(),
  timeZone: string().required(),
  address: string().nullable(),

  soonestBookingHours: number().oneOf(SoonestBookingHoursValues),
  bookingIncrementMinutes: number().oneOf(BookingIncrementValues),
}).noUnknown();

export type SharedBusiness = {
  id: string;
  name: string;
  address: string | null;
  description: string | null;
  link: string | null;
  logo: string | null;
  externalUrl: string | null;
  timeZone: string;
  relativeUrl: string;
  resolvedLink: string;
  paidFeatures: {
    multipleCalendars: boolean;
    noBranding: boolean;
    smsReminders: boolean;
  };
  customerPayments: {
    stripeAccountId?: string;
  };
};
export type BusinessPayload = Asserts<typeof BusinessSchema>;
export type BusinessKey = keyof BusinessPayload;

export type PersistedBusiness = SharedBusiness &
  BusinessPayload & {
    url: string;
    plan: BusinessPlan;
    planName: string;
    customerPayments: {
      enabled: boolean;
      currency?: string;
    };
    hasAppointments: boolean;
    allContactsGroupId: string;
  };

export const EmptyBusiness: BusinessPayload = {
  name: "",
  description: null,
  logo: null,
  timeZone: "",
};
