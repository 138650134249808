import { AppointmentLinkBlockPayload, PersistedAppointmentLinkBlock } from "schemas";
import { Loader, Markdown, TextAccent, flexStyles } from "ui";
import { useState } from "react";
import { BottomWrapper, Button, RadioCard } from "../ui";
import { useAppointments } from "../../store";

interface Props {
  initialValues: PersistedAppointmentLinkBlock;
  onSubmit: (payload: AppointmentLinkBlockPayload) => Promise<void>;
  cancelPath: string;
}
export function AppointmentLinkForm({ initialValues, onSubmit, cancelPath }: Props) {
  const { appointments, loading } = useAppointments();
  const [checked, setChecked] = useState<string | null>(initialValues.appointmentId);
  const onClick = async (id: string) => {
    setChecked(id);
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <div className={flexStyles.vert050}>
        {appointments.length === 0 && (
          <>
            <TextAccent>No appointments found</TextAccent>
            <Button to="/content/add/0" buttonType="secondary">
              Create one now
            </Button>
          </>
        )}
        {appointments.map((appointment) => (
          <RadioCard
            key={appointment.id}
            onClick={() => onClick(appointment.id)}
            title={<TextAccent>{appointment.name}</TextAccent>}
            checked={checked === appointment.id}
          >
            {appointment.description && <Markdown>{appointment.description}</Markdown>}
          </RadioCard>
        ))}
      </div>
      <BottomWrapper>
        <Button
          disabled={!checked}
          onClick={() => checked && onSubmit({ kind: "AppointmentLinkBlock", appointmentId: checked })}
        >
          Save
        </Button>
        <Button buttonType="tertiary" to={cancelPath}>
          Cancel
        </Button>
      </BottomWrapper>
    </>
  );
}
