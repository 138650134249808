import { BusinessPayload, BusinessSchema } from "schemas";
import {
  DescriptionItalic,
  FileLanding,
  FormProvider,
  FormTag,
  ImageInput,
  InlineTextInput,
  PopoverLinkItem,
  borderStyles,
  flexStyles,
  marginStyles,
  opacityStyles,
  textStyles,
} from "ui";
import classNames from "classnames";
import { Menu } from "./Menu";
import { updateBusiness, updateCampaign, uploadImage, useCampaign, useCurrentBusiness } from "../../../store";
import { EditWrapper } from "../../blocks";
import { useNavigate, useLocation } from "react-router";

export function EditCampaignLogo() {
  const business = useCurrentBusiness();
  const { campaign } = useCampaign();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const open = pathname.endsWith("blocks/logo");

  const onClick = () => {
    const to = open ? "." : `blocks/logo`;
    navigate(to, { relative: "path", preventScrollReset: true });
  };

  const onSubmit = async (payload: BusinessPayload) => {
    await updateBusiness(payload);
    navigate(".");
  };

  const toggleLogo = async (showLogo: boolean) => {
    if (campaign) {
      await updateCampaign(campaign.id, { ...campaign, showLogo });
      navigate(".");
    }
  };

  const extraLinks: PopoverLinkItem[] = campaign
    ? [
        {
          iconType: campaign.showLogo ? "Hide" : "Show",
          label: campaign.showLogo ? "Hide logo" : "Show logo",
          onClick: async () => await toggleLogo(!campaign.showLogo),
        },
      ]
    : [];

  const editor = open && (
    <FormProvider<BusinessPayload> schema={BusinessSchema} defaultValues={business}>
      <FormTag<BusinessPayload> onSubmit={onSubmit}>
        <ImageInput
          className={flexStyles.alignSelfCenter}
          uploadImage={uploadImage}
          urlValue
          cropAspect="Round"
          name="logo"
        />
        <InlineTextInput<BusinessPayload>
          label="Logo URL"
          size="Text"
          name="externalUrl"
          placeholder="https://..."
          onSubmit={onSubmit}
        />
        <Menu<BusinessPayload> onSubmit={onSubmit} onCancel={() => navigate(".")} extraLinks={extraLinks} />
      </FormTag>
    </FormProvider>
  );
  return (
    <EditWrapper open={open} onClick={onClick} editElement={editor}>
      {business.logo ? (
        <>
          <img
            src={`${business.logo}/logo`}
            width="160"
            height="160"
            className={classNames(borderStyles.radiusRound, marginStyles.centered, {
              [opacityStyles.opacity50]: !campaign?.showLogo,
            })}
            alt={`${business.name} logo`}
          />
          {!campaign?.showLogo && (
            <DescriptionItalic className={classNames(textStyles.neutral300, textStyles.textCenter)}>
              Hidden
            </DescriptionItalic>
          )}
        </>
      ) : (
        <FileLanding prompt="Add your logo" round />
      )}
    </EditWrapper>
  );
}
