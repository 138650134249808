import classNames from "classnames";
import { ReactNode } from "react";
import {
  PropsWithOptionalClick,
  borderStyles,
  flexStyles,
  gridStyles,
  heightStyles,
  iconStyles,
  paddingStyles,
  pointerStyles,
} from "ui";

interface Props extends PropsWithOptionalClick {
  title: ReactNode;
  left?: ReactNode;
  right?: ReactNode;
  noBorder?: boolean;
}

export function Header({ left, title, right, noBorder, onClick }: Props) {
  return (
    <div
      onClick={onClick}
      className={classNames(
        iconStyles.secondary300,
        gridStyles.gap050,
        flexStyles.alignCenter,
        heightStyles.min375,
        paddingStyles.py100,
        paddingStyles.px125,
        {
          [gridStyles.header2]: !left,
          [gridStyles.header3]: left,
          [borderStyles.bottomNeutral100]: !noBorder,
          [pointerStyles.cursor]: !!onClick,
        },
      )}
    >
      {left && <div className={gridStyles.areaL}>{left}</div>}
      <div className={gridStyles.areaT}>{title}</div>
      {right && <div className={classNames(iconStyles.primary, gridStyles.areaR)}>{right}</div>}
    </div>
  );
}
