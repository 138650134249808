import { useNavigate } from "react-router";
import { BookingIncrementOptions, BusinessPayload, BusinessSchema, SoonestBookingHoursOptions } from "schemas";
import { FormProvider, FormTag, Select, TextAccent, TextMain, paddingStyles } from "ui";
import { Overlay, Submit } from "../ui";
import { updateBusiness, useCurrentBusiness } from "../../store";

export function CalendarAvailability() {
  const navigate = useNavigate();
  const business = useCurrentBusiness();
  const onBack = () => navigate("..", { relative: "path" });
  const onSubmit = async (values: BusinessPayload) => {
    await updateBusiness({ ...business, ...values });
    onBack();
  };

  return (
    <Overlay
      className={paddingStyles.p125}
      header="Availability Rules"
      backTo={{ to: "/settings/calendars", label: "Calendars" }}
    >
      <FormProvider<BusinessPayload> defaultValues={business} schema={BusinessSchema}>
        <FormTag onSubmit={onSubmit}>
          <TextAccent>Booking increments</TextAccent>
          <TextMain>The size of the time slots that clients can book.</TextMain>
          <Select name="bookingIncrementMinutes" label="" options={BookingIncrementOptions} />

          <TextAccent>Earliest available booking slot</TextAccent>
          <TextMain>Specify when is the soonest your customers can book.</TextMain>
          <Select name="soonestBookingHours" label="" options={SoonestBookingHoursOptions} />

          <Submit disableClean cancelTo=".." label="Continue" />
        </FormTag>
      </FormProvider>
    </Overlay>
  );
}
