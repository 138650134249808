import { useNavigate, useParams } from "react-router";
import { AppointmentPayload, AppointmentSchema } from "schemas";
import { FormProvider, FormTag, Loader, Progress, TextMain, paddingStyles } from "ui";
import { Overlay, Submit } from "../ui";
import { FormSteps, Steps, stepPath } from "./steps";
import { updateAppointment, useAppointment } from "../../store";

type Params = {
  id: string;
};

type Props = {
  step: Steps;
};

export function Edit({ step }: Props) {
  const { id } = useParams() as Params;
  const navigate = useNavigate();

  const appointment = useAppointment(id);
  const steps = FormSteps;
  const formStep = steps.get(step)!;
  const currentStep = Array.from(steps.keys()).indexOf(step) + 1;
  const nextStep = formStep.next;
  const prevStep = formStep.prev;

  if (!appointment) {
    return <Loader />;
  }

  const label = nextStep ? "Save and continue" : "Save";

  const onSubmit = async (payload: AppointmentPayload) => {
    await updateAppointment(id, payload);
    navigate(stepPath(id, nextStep));
  };

  return (
    <FormProvider<AppointmentPayload> defaultValues={appointment} schema={AppointmentSchema}>
      <Overlay
        className={paddingStyles.p125}
        backTo={prevStep ? { to: stepPath(id, prevStep) } : undefined}
        header={`Edit ${appointment.name}`}
        title={formStep.title}
        subtitle={<TextMain>{formStep.description}</TextMain>}
        subHeaderElement={<Progress current={currentStep} total={steps.size} />}
      >
        <FormTag onSubmit={onSubmit}>
          <formStep.component id={id} />
          <Submit disableInvalid cancelTo="/content" label={label} />
        </FormTag>
      </Overlay>
    </FormProvider>
  );
}
