import { BusinessPayload, BusinessSchema } from "schemas";
import { FormProvider, FormTag, Markdown, TextMain, VisualTextArea, opacityStyles, textStyles } from "ui";
import { Menu } from "./Menu";
import { updateBusiness, useCurrentBusiness } from "../../../store";
import { EditWrapper } from "../../blocks";
import { useNavigate, useLocation } from "react-router";

export function EditBusinessDescription() {
  const business = useCurrentBusiness();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const open = pathname.endsWith("blocks/description");

  const onClick = () => {
    const to = open ? "." : `blocks/description`;
    navigate(to, { relative: "path", preventScrollReset: true });
  };

  const onSubmit = async (payload: BusinessPayload) => {
    await updateBusiness(payload);
    navigate(".");
  };

  const editor = open && (
    <FormProvider<BusinessPayload> schema={BusinessSchema} defaultValues={business}>
      <FormTag<BusinessPayload> onSubmit={onSubmit}>
        <VisualTextArea<BusinessPayload>
          autoFocus
          onSubmit={onSubmit}
          type="inline"
          name="description"
          placeholder="Add business description"
        />
        <Menu<BusinessPayload> onSubmit={onSubmit} onCancel={() => navigate(".")} />
      </FormTag>
    </FormProvider>
  );
  return (
    <EditWrapper className={textStyles.center} open={open} onClick={onClick} editElement={editor}>
      {business.description ? (
        <Markdown>{business.description}</Markdown>
      ) : (
        <TextMain className={opacityStyles.opacity50}>Add business description</TextMain>
      )}
    </EditWrapper>
  );
}
