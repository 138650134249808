import { SubheaderMain, TextMain } from "ui";
import { SuperCard } from "../ui";
import { useState } from "react";
import { CampaignStatType } from "schemas";
import { CampaignStatEmails } from "./CampaignStatEmails";

interface Props {
  label: string;
  count: number;
  statType?: CampaignStatType;
}

export function CampaignStat({ label, count, statType }: Props) {
  const [open, setOpen] = useState(false);
  const openable = !!statType && count > 0;
  return (
    <SuperCard
      title={<TextMain>{label}</TextMain>}
      right={<SubheaderMain>{count}</SubheaderMain>}
      headerOnClick={openable ? () => setOpen(!open) : undefined}
    >
      {openable && open && <CampaignStatEmails statType={statType} />}
    </SuperCard>
  );
}
