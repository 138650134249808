import { Asserts, number, object, string } from "yup";

export const ImageBlockSchema = object({
  image: string().url().required("Image is required"),
  alt: string().nullable(),
  url: string().nullable().url("Link must be a valid url"),
  caption: string().nullable(),
  pixelWidth: number().nullable(),
  kind: string().oneOf(["ImageBlock"]).required(),
}).noUnknown();
export type ImageBlockPayload = Asserts<typeof ImageBlockSchema> & {
  kind: "ImageBlock";
};
export type PersistedImageBlock = ImageBlockPayload & {
  _id: string;
};
