import {
  DescriptionAccent,
  Icon,
  Markdown,
  ShareLink,
  SubheaderMain,
  TextMain,
  borderStyles,
  flexStyles,
  textStyles,
} from "ui";
import { toast } from "react-toastify";
import { useUrlWithBack } from "../../hooks";
import { useCurrentBusiness, useCurrentUser } from "../../store";
import classNames from "classnames";
import { IconLink, SuperCard } from "../ui";

export function DetailsCard() {
  const business = useCurrentBusiness();
  const user = useCurrentUser();
  const editLink = useUrlWithBack("/content");

  return (
    <SuperCard
      title={<DescriptionAccent>Basics</DescriptionAccent>}
      left={<Icon iconType="Basics" />}
      right={<IconLink iconType="ChevronRight" to={editLink} />}
    >
      {business.logo && (
        <img
          src={`${business.logo}/logo`}
          width="64"
          height="64"
          className={classNames(borderStyles.radiusRound, flexStyles.alignSelfCenter)}
          alt={`${business.name} logo`}
        />
      )}
      <SubheaderMain className={flexStyles.alignSelfCenter}>{business.name}</SubheaderMain>
      <TextMain className={flexStyles.alignSelfCenter}>{user.email}</TextMain>
      {business.description && <Markdown className={textStyles.center}>{business.description}</Markdown>}
      <ShareLink
        className={flexStyles.alignSelfCenter}
        host={import.meta.env.REACT_APP_PUBLIC_DOMAIN}
        relativeUrl={business.relativeUrl}
        onCopy={toast}
      />
    </SuperCard>
  );
}
