"use client";

import { RefObject, useEffect, useRef } from "react";
import { useIntersection } from "react-use";

export function useScrollIntoView(trigger: boolean, ifNeeded: boolean = false): RefObject<HTMLDivElement> {
  const ref = useRef<HTMLDivElement>(null);
  const intersection = useIntersection(ref, {
    root: null,
    rootMargin: "50px",
  });

  useEffect(() => {
    if (trigger && (ifNeeded ? !intersection?.isIntersecting : true)) {
      ref.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    // eslint-disable-next-line
  }, [ref, trigger]);

  return ref;
}
