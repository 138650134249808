import { ThemePayload, getThemeName } from "schemas";
import {
  SmallTextMain,
  ThemedWrapper,
  bgStyles,
  borderStyles,
  flexStyles,
  heightStyles,
  paddingStyles,
  shadowStyles,
  squareStyles,
  transitionStyles,
  widthStyles,
} from "ui";
import classNames from "classnames";
import { useCurrentBusiness } from "../../store";
import { Box } from "./Box";

interface Props {
  theme: ThemePayload;
}
export function Preview({ theme }: Props) {
  const business = useCurrentBusiness();
  const name = getThemeName(theme);

  return (
    <ThemedWrapper
      className={classNames(
        flexStyles.vert050,
        flexStyles.alignCenter,
        paddingStyles.py125,
        paddingStyles.px075,
        shadowStyles.shadow30,
        transitionStyles.all200,
        widthStyles.width1000,
      )}
      theme={theme}
    >
      {business.logo ? (
        <img
          src={`${business.logo}/logo`}
          width="32"
          height="32"
          className={classNames(borderStyles.radiusRound, borderStyles.businessBorderColor)}
          alt={`${business.name} logo`}
        />
      ) : (
        <div
          className={classNames(
            borderStyles.radiusRound,
            borderStyles.businessBorderColorDashed,
            bgStyles.editorBackground,
            squareStyles.square200,
          )}
        />
      )}
      <SmallTextMain>{name}</SmallTextMain>
      <Box custom />
      <Box>
        <div className={flexStyles.horiz025}>
          <div
            className={classNames(
              borderStyles.tagBorderColorDashed,
              bgStyles.businessLight,
              widthStyles.width162,
              heightStyles.height075,
              borderStyles.radius037,
            )}
          />
          <div
            className={classNames(
              borderStyles.tagBorderColorDashed,
              bgStyles.businessLight,
              widthStyles.width162,
              heightStyles.height075,
              borderStyles.radius037,
            )}
          />
        </div>
        <Box className={bgStyles.businessButton} />
      </Box>
    </ThemedWrapper>
  );
}
