import { TextBlockPayload } from "schemas";
import { VisualTextArea } from "ui";

interface Props {
  onSubmit: (payload: TextBlockPayload) => Promise<void>;
}
export function TextEditor({ onSubmit }: Props) {
  return (
    <VisualTextArea<TextBlockPayload>
      onSubmit={onSubmit}
      type="inline"
      name="text"
      placeholder="Add a text block"
      fontSelectable
      textAlignmentSelectable
    />
  );
}
