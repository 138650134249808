import { PersistedFormAnswer } from "schemas";
import { Description, Loader, MediumContactInitials, TextAccent, flexStyles, textStyles } from "ui";
import { useContact } from "../../store";
import { FormAnswer } from "./FormAnswer";
import classNames from "classnames";
import { DateTime } from "luxon";
import { Link } from "react-router";
import { SuperCard } from "../ui";

interface Props {
  formAnswer: PersistedFormAnswer;
  showContactDetails?: boolean;
}
export function FormResponseCard({ formAnswer }: Props) {
  const contact = useContact(formAnswer.contactId);
  if (!contact) {
    return <Loader />;
  }

  return (
    <SuperCard
      title={
        <div className={classNames(flexStyles.vert050)}>
          <Link
            to={`contacts/${contact.id}`}
            className={classNames(flexStyles.horiz050, flexStyles.alignCenter, textStyles.decorationNone)}
          >
            <MediumContactInitials contact={contact} />
            <div>
              <TextAccent>{contact.displayName}</TextAccent>
              <Description>{DateTime.fromISO(formAnswer.createdAt).toRelative()}</Description>
            </div>
          </Link>
        </div>
      }
    >
      <FormAnswer formAnswer={formAnswer} />
    </SuperCard>
  );
}
