import {
  Help,
  HoldButton,
  Loader,
  Progress,
  SimpleError,
  SmallTextAccent,
  SmallTextMain,
  Span,
  Strong,
  TextMain,
  flexStyles,
  loaderStyles,
  marginStyles,
  paddingStyles,
  textStyles,
} from "ui";
import { useNavigate } from "react-router";
import { DateTime } from "luxon";
import { useState } from "react";
import classNames from "classnames";
import { Button, IconLink, Link, Overlay, SuperCard } from "../ui";
import { ExceededLimit } from "../contacts/ExceededLimit";
import {
  sendCampaign,
  sendTestCampaign,
  useCampaign,
  useCurrentBusiness,
  useEmailSettings,
  useOverContactsLimit,
} from "../../store";
import { pluralize } from "../../helpers";
import { SummaryContactGroup } from "./SummaryContactGroup";
import { uniq } from "es-toolkit";

export function Summary() {
  const navigate = useNavigate();
  const { campaign } = useCampaign();
  const business = useCurrentBusiness();
  const { emailSettings, loading: emailSettingsLoading } = useEmailSettings();
  const [sendDisabled, setSendDisabled] = useState(false);
  const limit = useOverContactsLimit(0);

  if (!campaign || emailSettingsLoading || !emailSettings) {
    return <Loader />;
  }

  const { recipients } = campaign;
  const { totalCount, unsubscribedCount } = recipients;

  const onSend = async () => {
    setSendDisabled(true);
    try {
      await sendCampaign(campaign.id);
      navigate("../sent", { relative: "path" });
    } finally {
      setSendDisabled(false);
    }
  };

  const contentErrors: { message: string; link: string }[] = [];
  if (!campaign.subject) {
    contentErrors.push({ message: "You haven't set a subject for this campaign", link: "../subject" });
  }
  if (!business.address) {
    contentErrors.push({
      message: "Business address required to send a campaign",
      link: "../content/editAddress",
    });
  }

  const campaignUnreasonable = totalCount === 0 || contentErrors.length > 0;

  return (
    <Overlay
      className={classNames(paddingStyles.p125, flexStyles.gap125)}
      backTo={{ to: "../subject", label: "Campaign Subject" }}
      title="Summary"
      subtitle={<TextMain>Lastly check your campaign.</TextMain>}
      header="Create a campaign"
      subHeaderElement={<Progress current={5} total={5} />}
    >
      <SuperCard
        title={<SmallTextAccent>Your sender details</SmallTextAccent>}
        right={<IconLink iconType="Edit" to="sender" />}
      >
        <TextMain>
          {emailSettings.resolvedFromName}{" "}
          <Span className={textStyles.neutral400}>&lt;{emailSettings.resolvedFromEmail}&gt;</Span>
        </TextMain>
        <SmallTextMain>
          <Span className={textStyles.neutral400}>Reply-to address: </Span>
          {emailSettings.resolvedReplyToEmail}
        </SmallTextMain>
        {!emailSettings.fromDomain && (
          <SmallTextMain className={marginStyles.mt100}>
            Want to send from your domain?
            <br />
            <Link className={textStyles.sizeInherit} to="/settings/domains">
              Add your domain
            </Link>
          </SmallTextMain>
        )}
      </SuperCard>
      <SuperCard
        title={<SmallTextAccent>Campaign Basics</SmallTextAccent>}
        right={<IconLink iconType="Edit" to="../subject" />}
      >
        <TextMain>Subject:</TextMain>
        <TextMain dir="auto">
          <Strong>{campaign.subject}</Strong>
        </TextMain>
      </SuperCard>
      <SuperCard
        title={<SmallTextAccent>Content</SmallTextAccent>}
        right={<IconLink iconType="Edit" to="../content" />}
      >
        {contentErrors.length > 0 ? (
          contentErrors.map((error) => (
            <>
              <SimpleError className={textStyles.size100} key={error.message}>
                {error.message} <Link to={error.link}>Fix here</Link>
              </SimpleError>
            </>
          ))
        ) : (
          <>
            <SmallTextMain>{campaign.subject}</SmallTextMain>
            <SmallTextMain className={textStyles.neutral400}>
              Last edited: {DateTime.fromISO(campaign.updatedAt!).toRelative()}
            </SmallTextMain>
          </>
        )}
      </SuperCard>
      <SuperCard
        title={
          <div className={flexStyles.horiz050}>
            <SmallTextAccent>Recipients</SmallTextAccent>
            <Help>
              <SmallTextAccent>Not seeing everyone you expect?</SmallTextAccent>
              <SmallTextMain>
                We only count subscribers who confirmed their email as recipients. If you need to import a list{" "}
                <a href="mailto:support@popsend.com">contact us.</a>
              </SmallTextMain>
            </Help>
          </div>
        }
        right={<IconLink iconType="Edit" to="../recipients" />}
      >
        <div className={flexStyles.vert125}>
          {totalCount === 0 ? (
            <SimpleError className={textStyles.size100}>
              You have not selected any recepients for this campaign <Link to={"../recipients"}>Fix here</Link>
            </SimpleError>
          ) : (
            <TextMain>
              Selected {totalCount} {pluralize("contact", totalCount)}
              <br />- {unsubscribedCount} unsubscribed
              <br />- {totalCount - unsubscribedCount} {pluralize("recipient", totalCount - unsubscribedCount)}
            </TextMain>
          )}
          {recipients.contactGroupIds.length > 0 && (
            <div className={flexStyles.vert050}>
              <SmallTextMain className={textStyles.neutral500}>Selected Groups:</SmallTextMain>
              {uniq(recipients.contactGroupIds).map((id) => (
                <SummaryContactGroup key={id} id={id} />
              ))}
            </div>
          )}
          {recipients.excludedContactGroupIds.length > 0 && (
            <div className={flexStyles.vert050}>
              <SmallTextMain className={textStyles.neutral500}>Excluded Groups:</SmallTextMain>
              {uniq(recipients.excludedContactGroupIds).map((id) => (
                <SummaryContactGroup key={id} id={id} />
              ))}
            </div>
          )}
        </div>
      </SuperCard>
      {limit?.overSendableThreshold && <ExceededLimit type="sendableThreshold" />}
      {limit?.overLimit && <ExceededLimit type="campaign" />}
      <HoldButton
        disabled={
          limit?.overLimit ||
          limit?.overSendableThreshold ||
          sendDisabled ||
          campaignUnreasonable ||
          campaign.status === "Sent"
        }
        onPressed={onSend}
        pressedContent={
          <>
            <Loader className={classNames(loaderStyles.size150)} fitted />
            Sending
          </>
        }
      >
        Press and hold to send
      </HoldButton>
      <Button buttonType="secondary" onClick={() => sendTestCampaign(campaign.id)}>
        Send a test email
      </Button>
    </Overlay>
  );
}
