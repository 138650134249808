import {
  CheckboxWithLabel,
  Description,
  DescriptionAccent,
  Divider,
  Icon,
  LinkInput,
  Select,
  TextAccent,
  TextInput,
  TextMain,
  flexStyles,
  iconStyles,
  marginStyles,
  paddingStyles,
  textStyles,
  widthStyles,
} from "ui";
import { FormQuestionPayload } from "schemas";
import { Draggable } from "react-beautiful-dnd";
import { useFormContext, useWatch } from "react-hook-form";
import classNames from "classnames";
import { useBackLocation } from "../../hooks";
import { changeDraggableOrder } from "../../helpers/orders";
import { QuestionCard } from "./QuestionCard";
import { Button, Drop, Overlay, Submit, SuperCard } from "../ui";
import { checkEntityLink, useContactGroupOptions, useCurrentBusiness } from "../../store";
const { REACT_APP_PUBLIC_DOMAIN } = import.meta.env;

export function Edit() {
  const questions = useWatch({ name: "questions" });
  const id = useWatch({ name: "id" });
  const backLocation = useBackLocation("/forms");
  const business = useCurrentBusiness();
  const linkBase = `${REACT_APP_PUBLIC_DOMAIN}/${business.resolvedLink}/`;
  const header = id ? "Edit form" : "Add form";
  const options = useContactGroupOptions();

  const { setValue } = useFormContext();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDragEnd = ({ destination, source }: any) => {
    if (destination && source.index !== destination.index) {
      const reorderedQuestions = changeDraggableOrder<FormQuestionPayload>(destination.index, source.index, questions);

      setValue("questions", reorderedQuestions, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
    }
  };

  const checkLink = async (link: string, abortController: AbortController) => {
    const available = await checkEntityLink({ type: "form", link, id }, abortController);
    return available ? "" : `${link} is already taken`;
  };

  return (
    <Overlay
      className={classNames(paddingStyles.p125, flexStyles.gap125)}
      header={header}
      backTo={{ to: backLocation, label: "List" }}
    >
      <div className={flexStyles.vert025}>
        <TextInput name="name" label="Form name" />
        <Description className={textStyles.neutral400}>It will be the form&apos;s header.</Description>
      </div>
      <TextInput name="description" label="Description" />
      <LinkInput base={linkBase} name="handle" label="Link" slugFrom={null} check={checkLink} />
      <CheckboxWithLabel name="subscribesContacts" className={textStyles.neutral600}>
        Subscribe responders to your newsletter
      </CheckboxWithLabel>
      {options.length > 0 && (
        <Select options={options} name="destinationContactGroup" label="Assign to submissions to a group (optional)" />
      )}
      <Divider />
      <TextAccent>Questions</TextAccent>
      <TextMain>Add questions to your form.</TextMain>

      <Drop onDragEnd={onDragEnd}>
        {questions.map((question: FormQuestionPayload, index: number) => (
          <Draggable
            key={`${question.question}${question.questionType}`}
            draggableId={`${question.question}${question.questionType}`}
            index={index}
          >
            {(provided) => (
              <div className={paddingStyles.pb100} ref={provided.innerRef} {...provided.draggableProps}>
                <QuestionCard
                  dragHandleProps={provided.dragHandleProps}
                  question={question}
                  index={index}
                  key={`${question.question}/${question.questionType}/card`}
                />
              </div>
            )}
          </Draggable>
        ))}
        <div className={marginStyles.mis200}>
          <SuperCard
            left={<Icon className={iconStyles.secondary300} iconType="InPerson" />}
            title={<DescriptionAccent>Contact details</DescriptionAccent>}
          >
            <TextMain>Name and email</TextMain>
          </SuperCard>
        </div>
      </Drop>
      <Button className={classNames(flexStyles.alignSelfCenter, widthStyles.auto)} to="add">
        Add a question
      </Button>

      <Submit label="Apply changes" disableClean disableInvalid cancelTo={backLocation} cancelType="button" />
    </Overlay>
  );
}
