import { BlockKind } from "schemas";
import { useIdParam } from "../../hooks";

export type BlockConsumer = "HomePage" | "Page" | "Campaign";
export type ExtendedBlockKind = BlockKind | "HeadingBlock" | "DividerBlock";
const AvailableBlocks: Record<BlockConsumer, ExtendedBlockKind[]> = {
  HomePage: [
    "AppointmentBlock",
    "DividerBlock",
    "EmailBlock",
    "FormLinkBlock",
    "HeadingBlock",
    "ImageBlock",
    "LinkBlock",
    "PageBlock",
    "PhoneBlock",
    "SocialLinksBlock",
    "TextBlock",
    "VideoBlock",
  ],
  Page: [
    "DividerBlock",
    "EmailBlock",
    "FormLinkBlock",
    "HeadingBlock",
    "ImageBlock",
    "LinkBlock",
    "PhoneBlock",
    "SocialLinksBlock",
    "TextBlock",
    "VideoBlock",
  ],
  Campaign: [
    "AppointmentLinkBlock",
    "DividerBlock",
    "EmailBlock",
    "FormLinkBlock",
    "HeadingBlock",
    "ImageBlock",
    "LinkBlock",
    "PhoneBlock",
    "SocialLinksBlock",
    "TextBlock",
    "VideoLinkBlock",
  ],
};

export function supportsBlock(consumer: BlockConsumer, kind: ExtendedBlockKind) {
  return AvailableBlocks[consumer].includes(kind);
}

export function useBlockConsumer(): BlockConsumer {
  const pageId = useIdParam("pageId");
  const campaignId = useIdParam("campaignId");
  if (pageId) {
    return "Page";
  }
  if (campaignId) {
    return "Campaign";
  }
  return "HomePage";
}
