import { SignupPayload, UserPayload } from "schemas";
import { apiRequest } from "../apiRequest";
import { DateTime } from "luxon";

interface Args {
  payload: SignupPayload;
}
export async function signup({ payload }: Args): Promise<UserPayload | null> {
  const timeZone = DateTime.local().zoneName;
  try {
    const user = await apiRequest<UserPayload>({
      url: "auth/signup",
      method: "POST",
      body: JSON.stringify({
        captchaResponse: payload.captchaResponse,
        business: {
          ...payload.business,
          timeZone,
        },
        user: payload.user,
      }),
    });
    return user;
  } catch (e) {
    const error = new Error();
    // @ts-expect-error errors
    error.message = e.response.data.message;
    throw error;
  }
}
