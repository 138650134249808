import { SharedBusiness } from "schemas";
import classNames from "classnames";
import { BusinessDetailsCard, PopoverMenu, flexStyles } from "ui";
import { EditWrapper } from "../blocks";
import { useNavigate } from "react-router";
import { useLocation } from "react-router";

interface Props {
  business: SharedBusiness;
}
export function BusinessAddressPreview({ business }: Props) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onClick = () => {
    navigate("editAddress", { preventScrollReset: true });
  };
  const open = pathname.endsWith("blocks/address");

  const card = <BusinessDetailsCard business={business} onClick={onClick} />;

  const editElement = open && (
    <>
      {card}
      {business.address && (
        <PopoverMenu
          open
          links={[
            { label: "Cancel", onClick: () => navigate("."), iconType: "X" },
            { label: "Edit", onClick, iconType: "Edit" },
          ]}
        />
      )}
    </>
  );

  return (
    <EditWrapper
      open={open}
      onClick={() => navigate(business.address ? "blocks/address" : "editAddress")}
      className={classNames(flexStyles.alignCenter, flexStyles.gap050)}
      editElement={editElement}
    >
      {card}
    </EditWrapper>
  );
}
