import { CampaignStatType } from "schemas";
import { apiRequest } from "../apiRequest";

export async function fetchCampaignStatEmails(id: string, type: CampaignStatType): Promise<string[]> {
  const { emails } = await apiRequest<{ emails: string[] }>({
    url: `campaigns/${id}/stats/${type}`,
  });

  return emails;
}
