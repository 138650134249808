import { PersistedImageBlock } from "schemas";
import { Card } from "../Card";
import classNames from "classnames";
import {
  displayStyles,
  flexStyles,
  imageStyles,
  overflowStyles,
  paddingStyles,
  pointerStyles,
  textStyles,
  widthStyles,
} from "../styles";

interface Props {
  block: PersistedImageBlock;
  disabled: boolean;
}

export function ImageCard({ block, disabled }: Props) {
  const onClick =
    disabled || !block.url
      ? undefined
      : () => {
          block.url && window.open(block.url, "_blank");
        };

  const alt = block.alt || block.caption || undefined;
  return (
    <Card
      noBackground
      onClick={onClick}
      className={classNames(widthStyles.full, overflowStyles.hidden, paddingStyles.p0, {
        [pointerStyles.cursor]: block.url,
      })}
    >
      <figure className={classNames(flexStyles.vert050)}>
        <picture className={flexStyles.alignSelfCenter}>
          <source srcSet={`${block.image}/feed`} type="image/webp" />
          <img
            style={{ maxWidth: block.pixelWidth || undefined }}
            className={classNames(displayStyles.block, widthStyles.maxWidthFull, imageStyles.fitContain)}
            src={`${block.image}/feed`}
            alt={alt}
          />
        </picture>
        {block.caption && (
          <figcaption
            className={classNames(
              textStyles.businessTextColor,
              textStyles.italic,
              textStyles.center,
              textStyles.size075,
              flexStyles.alignSelfStretch,
            )}
          >
            {block.caption}
          </figcaption>
        )}
      </figure>
    </Card>
  );
}
